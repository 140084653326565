<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content-width">
      <!-- Course -->
      <div class="col-span-1">
        <Select
          v-bind:data="data"
          v-bind:typeSelected="this.filtered.type"
          v-bind:yearSelected="this.filtered.year"
          v-bind:semesterSelected="this.filtered.sems"
          v-bind:prevCourse="this.filtered.prev"
          v-for="data in yearCourses"
          :key="data.id"
          @changeSelected="changeSelected"
          @click="changeSelectedCourse"
        />
      </div>

      <!-- Header -->
      <div class="manage-header">
        <div class="row">
          <p class="table-header">ข้อมูลนักศึกษา</p>

          <!-- Program filter -->
          <p class="my-auto">หลักสูตร</p>
          <select v-model="filtered.program" @change="changeSelectedCourse()" class="select-bar">
            <option value="ALL">ทั้งหมด</option>
            <option v-for="program in programs" :key="program.id" :value="program.code">{{ program.name }}</option>
          </select>
        </div>

        <!-- Table loading -->
        <div v-if="table_loading" class="mt-8">
          <div class="loader m-auto"></div>
        </div>
        <div v-else-if="filtered.student_list.length > 0">
          <!-- Student list (Table) -->
          <table>
            <!-- Table header -->
            <thead>
              <tr>
                <th class="w-1/12">ลำดับ</th>
                <th class="w-1/12">รหัสนักศึกษา</th>
                <th class="w-3/12">ชื่อ-นามสกุล</th>
                <th class="w-7/12">หัวข้อโครงงาน</th>
                <th></th>
              </tr>
            </thead>
            <!-- Table data -->
            <tbody>
              <tr><td colspan="7"><hr></td></tr>
              <tr v-for="(student, index) in filtered.student_list" :key="student.id">
                <td>{{ index + 1 }}</td>
                <td>{{ student['code'] }}</td>
                <td>{{ student['name'] }}</td>
                <td class="text-left">{{ student['topic_th'] }}<br>({{ student['topic_en'] }})</td>
                <td class="text-right">
                  <router-link :to="'/project/' + student['project_id'] + '/'">
                    <button class="view-button mx-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="white">
                        <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Not Found -->
        <div v-else class="text-not-found">ไม่พบข้อมูลที่สอดคล้องกับตัวกรองข้อมูลที่เลือก</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import Select from "@/components/Select"
import { changeProgramName, changeProgramByType, yearCourseSelect } from "../mixins/other"

export default {
  name: "StudentManage",
  components: {
    NavBar,
    Select,
  },
  data() {
    return {
      loading: true,
      table_loading: true,
      courses: null,
      programs: [],
      years: [],
      yearCourses: [],
      filtered: {
        student_list: null
      },
      student_list: [],
      role: null,
    }
  },
  async created() {
    document.title = "ข้อมูลนักศึกษา | Carbon"
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role === "STD") {
      this.$router.push("/")
    }
    await this.setSelected()
    let { years, yearCourses } = yearCourseSelect(this.courses)
    this.years = years
    this.yearCourses = yearCourses
    await this.changeSelectedCourse()
    this.programs = await this.$store.dispatch("getProgram")
    this.programs = changeProgramName(changeProgramByType(this.filtered.type))
    this.loading = false
    this.table_loading = false
  },
  methods: {
    async setSelected() {
      this.courses = await this.$store.dispatch("getCourse")
      this.courses.sort(function (c1, c2) { return c1.code - c2.code })
      this.courses.sort(function (c1, c2) { return c2.year - c1.year })
      this.filtered.course = this.courses[0]
      this.filtered.year = this.filtered.course.year
      this.filtered.type = this.filtered.course.type
      this.filtered.sems = (this.courses[0].type == 'PJ') ? null : this.filtered.course.semester
      this.filtered.prev = null
      this.filtered.program = "ALL"
    },
    changeSelected(year, type, sems, prev) {
      this.filtered.year = year
      this.filtered.type = type
      this.filtered.sems = sems
      this.filtered.prev = prev
      this.filtered.program = "ALL"
      this.programs = changeProgramName(changeProgramByType(this.filtered.type))
    },
    async projectFilter() {
      this.table_loading = true
      this.student_list = []
      this.filtered.student_list = []
      let data =  Object.values(this.filtered.course)
      this.student_list = await this.$store.dispatch("getProjectWithMemberByCourseId", { courses: data })
      
      if (this.student_list.length > 0) {
        this.student_list.filter((student) => {
          if (student['course_program'] == this.filtered.program && this.filtered.course.includes(student['course_id']) || this.filtered.program == 'ALL' && this.filtered.course.includes(student['course_id'])) {
            this.filtered.student_list.push(student)
          }
        })
      }
      this.table_loading = false
    },
    async changeSelectedCourse() {
      this.filtered.course = []
      let courseList = []
      this.courses.filter((course) => {
        if (course.year == this.filtered.year && course.type == this.filtered.type && course.semester == this.filtered.sems && this.filtered.type == 'IS') {
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && this.filtered.type == 'CP') {
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && course.prev_course == null && this.filtered.type == 'PJ' && this.filtered.prev == null) {
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && course.prev_course != null && this.filtered.type == 'PJ' && this.filtered.prev != null) {
          courseList.push([course.prev_course, course.program_code])
        }
      })
      courseList.filter((course) => {
        if (this.filtered.program == 'ALL' || this.filtered.program == course[1]) {
          this.filtered.course.push(course[0])
        }
      })
      await this.projectFilter()
    },
  },
}
</script>
